<template>
  <section class="auth auth--reset">
    <div class="auth__container">
      <div class="auth__logo">
        <FullLogoIcon />
      </div>
      <form
        class="auth__form"
        autocomplete="off"
        spellcheck="false"
        ref="form"
        @submit.prevent="restorePassword"
      >
        <h1 class="auth__title">Восстановить пароль</h1>
        <InputComponent
          title="Электронная почта"
          type="email"
          :error="
            ($v.restoreForm.email.value.$error && submitStatus === 'ERROR') || submitStatus === 'INVALID'
          "
          v-model.trim.lazy="$v.restoreForm.email.value.$model"
        />
        <button type="submit" class="btn" :class="{ 'btn--loading': submitStatus === 'PENDING' }">
          <template v-if="submitStatus !== 'PENDING'">Восстановить</template>
        </button>
      </form>
      <div class="auth__switch">
        <span>У вас ещё нет аккаунта?</span>
        <router-link :to="{ name: 'Registration' }"> Зарегистрироваться</router-link>
      </div>
    </div>
  </section>
</template>

<script>
import FullLogoIcon from "@/components/svg/FullLogoIcon";
import InputComponent from "@/components/inputs/InputComponent";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
  name: "RestorePasswordPage",
  data() {
    return {
      restoreForm: {
        email: {
          message: null,
          value: null,
        },
      },
      submitStatus: null,
    };
  },
  validations: {
    restoreForm: {
      email: {
        value: {
          required,
          email,
          minLength: minLength(5),
        },
      },
    },
  },
  methods: {
    async restorePassword() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.submitStatus = "PENDING";
        this.$store
          .dispatch("RESTORE_PASSWORD", {
            context: this,
            variables: {
              email: this.restoreForm.email.value,
            },
          })
          .then(() => {
            this.submitStatus = "OK";
            this.$notify({
              title: "Успешно",
              text: "Письмо с инструкциями отправлено на Ваш e-mail",
              type: "success",
              duration: 10000,
            });
            this.$router.push({ name: "Login" });
          })
          .catch(() => {
            this.submitStatus = "INVALID";
          });
      }
    },
  },
  components: { InputComponent, FullLogoIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/auth"
</style>
